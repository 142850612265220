<template>
  <div class="multilevel">
    <el-table :data="tableData" stripe :height="tableHeight - 26" style="width: 100%">
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column prop="companyName" label="企业"></el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column label="车牌颜色">
        <template slot-scope="scope">
          {{ typeObj[Number(scope.row.licensePlateColor)] }}
        </template>
      </el-table-column>
      <el-table-column label="车辆类型" :show-overflow-tooltip="true"
        ><template slot-scope="scope">
          {{ modelObj[Number(scope.row.type)] }}
        </template></el-table-column
      >
         <!-- 2023.04.27 谢 迭代 拆分两列：单次、累计 -->
      <!-- <el-table-column prop="overTimeNum" label="超时疲劳数量"></el-table-column> -->
      <el-table-column prop="singleOvertimeNum" label="单次超时疲劳数量"></el-table-column>
      <el-table-column prop="accumulateOvertimeNum" label="累计超时疲劳数量"></el-table-column>
      <!-- 2023.04.27 谢 迭代 拆分两列：单次、累计 -->
      <el-table-column prop="physiologyNum" label="生理疲劳数量"></el-table-column>
      <el-table-column prop="fatigueDuration" label="疲劳时长(分)"></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
    },
    tableData: {
      type: Array,
    },
    total: {
      type: Number,
    },
    modelObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    typeObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    height(val, oldVal) {
      setTimeout(() => {
        this.tableHeight = val;
      }, 200);
    },
  },

  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10,
      },
      tableHeight: 0,
    };
  },

  methods: {
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.$emit("sendInfo", this.pageObj);
    },
    handleCurrentChange(val) {
      this.pageObj.currentPage = val;
      this.$emit("sendInfo", this.pageObj);
    },
    reset() {
      this.pageObj.currentPage = 1;
      this.pageObj.pageSize = 10;
    },
  },
};
</script>

<style></style>
